import * as _react2 from "react";
var _react = "default" in _react2 ? _react2.default : _react2;
import * as _reactDom2 from "react-dom";
var _reactDom = "default" in _reactDom2 ? _reactDom2.default : _reactDom2;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (e, t) {
  exports = t(_react, _reactDom);
}(self, function (e, t) {
  return (() => {
    "use strict";

    var n = {
        297: t => {
          t.exports = e;
        },
        268: e => {
          e.exports = t;
        }
      },
      s = {};
    function r(e) {
      var t = s[e];
      if (void 0 !== t) return t.exports;
      var i = s[e] = {
        exports: {}
      };
      return n[e](i, i.exports, r), i.exports;
    }
    r.n = e => {
      var t = e && e.__esModule ? () => e.default : () => e;
      return r.d(t, {
        a: t
      }), t;
    }, r.d = (e, t) => {
      for (var n in t) r.o(t, n) && !r.o(e, n) && Object.defineProperty(e, n, {
        enumerable: !0,
        get: t[n]
      });
    }, r.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), r.r = e => {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    };
    var i = {};
    return (() => {
      r.r(i), r.d(i, {
        default: () => es
      });
      var e = r(297),
        t = r.n(e);
      let n = _();
      const s = e => g(e, n);
      let o = _();
      s.write = e => g(e, o);
      let a = _();
      s.onStart = e => g(e, a);
      let u = _();
      s.onFrame = e => g(e, u);
      let l = _();
      s.onFinish = e => g(e, l);
      let c = [];
      s.setTimeout = (e, t) => {
        let n = s.now() + t,
          r = () => {
            let e = c.findIndex(e => e.cancel == r);
            ~e && c.splice(e, 1), f -= ~e ? 1 : 0;
          },
          i = {
            time: n,
            handler: e,
            cancel: r
          };
        return c.splice(d(n), 0, i), f += 1, y(), i;
      };
      let d = e => ~(~c.findIndex(t => t.time > e) || ~c.length);
      s.cancel = e => {
        a.delete(e), u.delete(e), n.delete(e), o.delete(e), l.delete(e);
      }, s.sync = e => {
        m = !0, s.batchedUpdates(e), m = !1;
      }, s.throttle = e => {
        let t;
        function n() {
          try {
            e(...t);
          } finally {
            t = null;
          }
        }
        function r(...e) {
          t = e, s.onStart(n);
        }
        return r.handler = e, r.cancel = () => {
          a.delete(n), t = null;
        }, r;
      };
      let h = "undefined" != typeof window ? window.requestAnimationFrame : () => {};
      s.use = e => h = e, s.now = "undefined" != typeof performance ? () => performance.now() : Date.now, s.batchedUpdates = e => e(), s.catch = console.error, s.frameLoop = "always", s.advance = () => {
        "demand" !== s.frameLoop ? console.warn("Cannot call the manual advancement of rafz whilst frameLoop is not set as demand") : b();
      };
      let p = -1,
        f = 0,
        m = !1;
      function g(e, t) {
        m ? (t.delete(e), e(0)) : (t.add(e), y());
      }
      function y() {
        p < 0 && (p = 0, "demand" !== s.frameLoop && h(v));
      }
      function v() {
        ~p && (h(v), s.batchedUpdates(b));
      }
      function b() {
        let e = p;
        p = s.now();
        let t = d(p);
        t && (w(c.splice(0, t), e => e.handler()), f -= t), a.flush(), n.flush(e ? Math.min(64, p - e) : 16.667), u.flush(), o.flush(), l.flush(), f || (p = -1);
      }
      function _() {
        let e = new Set(),
          t = e;
        return {
          add(n) {
            f += t != e || e.has(n) ? 0 : 1, e.add(n);
          },
          delete: n => (f -= t == e && e.has(n) ? 1 : 0, e.delete(n)),
          flush(n) {
            t.size && (e = new Set(), f -= t.size, w(t, t => t(n) && e.add(t)), f += e.size, t = e);
          }
        };
      }
      function w(e, t) {
        e.forEach(e => {
          try {
            t(e);
          } catch (e) {
            s.catch(e);
          }
        });
      }
      function k() {}
      const P = {
        arr: Array.isArray,
        obj: e => !!e && "Object" === e.constructor.name,
        fun: e => "function" == typeof e,
        str: e => "string" == typeof e,
        num: e => "number" == typeof e,
        und: e => void 0 === e
      };
      function S(e, t) {
        if (P.arr(e)) {
          if (!P.arr(t) || e.length !== t.length) return !1;
          for (let n = 0; n < e.length; n++) if (e[n] !== t[n]) return !1;
          return !0;
        }
        return e === t;
      }
      const x = (e, t) => e.forEach(t);
      function V(e, t, n) {
        if (P.arr(e)) for (let s = 0; s < e.length; s++) t.call(n, e[s], `${s}`);else for (const s in e) e.hasOwnProperty(s) && t.call(n, e[s], s);
      }
      const O = e => P.und(e) ? [] : P.arr(e) ? e : [e];
      function C(e, t) {
        if (e.size) {
          const n = Array.from(e);
          e.clear(), x(n, t);
        }
      }
      const A = (e, ...t) => C(e, e => e(...t)),
        I = () => "undefined" == typeof window || !window.navigator || /ServerSideRendering|^Deno\//.test(window.navigator.userAgent);
      let j,
        R,
        M = null,
        E = !1,
        q = k;
      var N = Object.freeze({
        __proto__: null,
        get createStringInterpolator() {
          return j;
        },
        get to() {
          return R;
        },
        get colors() {
          return M;
        },
        get skipAnimation() {
          return E;
        },
        get willAdvance() {
          return q;
        },
        assign: e => {
          e.to && (R = e.to), e.now && (s.now = e.now), void 0 !== e.colors && (M = e.colors), null != e.skipAnimation && (E = e.skipAnimation), e.createStringInterpolator && (j = e.createStringInterpolator), e.requestAnimationFrame && s.use(e.requestAnimationFrame), e.batchedUpdates && (s.batchedUpdates = e.batchedUpdates), e.willAdvance && (q = e.willAdvance), e.frameLoop && (s.frameLoop = e.frameLoop);
        }
      });
      const T = new Set();
      let z = [],
        $ = [],
        F = 0;
      const U = {
        get idle() {
          return !T.size && !z.length;
        },
        start(e) {
          F > e.priority ? (T.add(e), s.onStart(D)) : (L(e), s(W));
        },
        advance: W,
        sort(e) {
          if (F) s.onFrame(() => U.sort(e));else {
            const t = z.indexOf(e);
            ~t && (z.splice(t, 1), Q(e));
          }
        },
        clear() {
          z = [], T.clear();
        }
      };
      function D() {
        T.forEach(L), T.clear(), s(W);
      }
      function L(e) {
        z.includes(e) || Q(e);
      }
      function Q(e) {
        z.splice(function (t, n) {
          const s = t.findIndex(t => t.priority > e.priority);
          return s < 0 ? t.length : s;
        }(z), 0, e);
      }
      function W(e) {
        const t = $;
        for (let n = 0; n < z.length; n++) {
          const s = z[n];
          F = s.priority, s.idle || (q(s), s.advance(e), s.idle || t.push(s));
        }
        return F = 0, $ = z, $.length = 0, z = t, z.length > 0;
      }
      const G = "[-+]?\\d*\\.?\\d+",
        B = G + "%";
      function Y(...e) {
        return "\\(\\s*(" + e.join(")\\s*,\\s*(") + ")\\s*\\)";
      }
      const Z = new RegExp("rgb" + Y(G, G, G)),
        H = new RegExp("rgba" + Y(G, G, G, G)),
        J = new RegExp("hsl" + Y(G, B, B)),
        X = new RegExp("hsla" + Y(G, B, B, G)),
        K = /^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
        ee = /^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
        te = /^#([0-9a-fA-F]{6})$/,
        ne = /^#([0-9a-fA-F]{8})$/;
      function se(e, t, n) {
        return n < 0 && (n += 1), n > 1 && (n -= 1), n < 1 / 6 ? e + 6 * (t - e) * n : n < 0.5 ? t : n < 2 / 3 ? e + (t - e) * (2 / 3 - n) * 6 : e;
      }
      function re(e, t, n) {
        const s = n < 0.5 ? n * (1 + t) : n + t - n * t,
          r = 2 * n - s,
          i = se(r, s, e + 1 / 3),
          o = se(r, s, e),
          a = se(r, s, e - 1 / 3);
        return Math.round(255 * i) << 24 | Math.round(255 * o) << 16 | Math.round(255 * a) << 8;
      }
      function ie(e) {
        const t = parseInt(e, 10);
        return t < 0 ? 0 : t > 255 ? 255 : t;
      }
      function oe(e) {
        return (parseFloat(e) % 360 + 360) % 360 / 360;
      }
      function ae(e) {
        const t = parseFloat(e);
        return t < 0 ? 0 : t > 1 ? 255 : Math.round(255 * t);
      }
      function ue(e) {
        const t = parseFloat(e);
        return t < 0 ? 0 : t > 100 ? 1 : t / 100;
      }
      function le(e) {
        let t = function (e) {
          let t;
          return "number" == typeof e ? e >>> 0 === e && e >= 0 && e <= 4294967295 ? e : null : (t = te.exec(e)) ? parseInt(t[1] + "ff", 16) >>> 0 : M && void 0 !== M[e] ? M[e] : (t = Z.exec(e)) ? (ie(t[1]) << 24 | ie(t[2]) << 16 | ie(t[3]) << 8 | 255) >>> 0 : (t = H.exec(e)) ? (ie(t[1]) << 24 | ie(t[2]) << 16 | ie(t[3]) << 8 | ae(t[4])) >>> 0 : (t = K.exec(e)) ? parseInt(t[1] + t[1] + t[2] + t[2] + t[3] + t[3] + "ff", 16) >>> 0 : (t = ne.exec(e)) ? parseInt(t[1], 16) >>> 0 : (t = ee.exec(e)) ? parseInt(t[1] + t[1] + t[2] + t[2] + t[3] + t[3] + t[4] + t[4], 16) >>> 0 : (t = J.exec(e)) ? (255 | re(oe(t[1]), ue(t[2]), ue(t[3]))) >>> 0 : (t = X.exec(e)) ? (re(oe(t[1]), ue(t[2]), ue(t[3])) | ae(t[4])) >>> 0 : null;
        }(e);
        return null === t ? e : (t = t || 0, `rgba(${(4278190080 & t) >>> 24}, ${(16711680 & t) >>> 16}, ${(65280 & t) >>> 8}, ${(255 & t) / 255})`);
      }
      const ce = (e, t, n) => {
        if (P.fun(e)) return e;
        if (P.arr(e)) return ce({
          range: e,
          output: t,
          extrapolate: n
        });
        if (P.str(e.output[0])) return j(e);
        const s = e,
          r = s.output,
          i = s.range || [0, 1],
          o = s.extrapolateLeft || s.extrapolate || "extend",
          a = s.extrapolateRight || s.extrapolate || "extend",
          u = s.easing || (e => e);
        return e => {
          const t = function (e, t) {
            for (var n = 1; n < t.length - 1 && !(t[n] >= e); ++n);
            return n - 1;
          }(e, i);
          return function (e, t, n, s, r, i, o, a, u) {
            let l = u ? u(e) : e;
            if (l < t) {
              if ("identity" === o) return l;
              "clamp" === o && (l = t);
            }
            if (l > n) {
              if ("identity" === a) return l;
              "clamp" === a && (l = n);
            }
            return s === r ? s : t === n ? e <= t ? s : r : (t === -1 / 0 ? l = -l : n === 1 / 0 ? l -= t : l = (l - t) / (n - t), l = i(l), s === -1 / 0 ? l = -l : r === 1 / 0 ? l += s : l = l * (r - s) + s, l);
          }(e, i[t], i[t + 1], r[t], r[t + 1], u, o, a, s.map);
        };
      };
      function de() {
        return (de = Object.assign || function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var n = arguments[t];
            for (var s in n) Object.prototype.hasOwnProperty.call(n, s) && (e[s] = n[s]);
          }
          return e;
        }).apply(this || _global, arguments);
      }
      const he = Symbol.for("FluidValue.get"),
        pe = Symbol.for("FluidValue.observers"),
        fe = e => Boolean(e && e[he]),
        me = e => e && e[he] ? e[he]() : e,
        ge = e => e[pe] || null;
      function ye(e, t) {
        let n = e[pe];
        n && n.forEach(e => {
          !function (e, t) {
            e.eventObserved ? e.eventObserved(t) : e(t);
          }(e, t);
        });
      }
      class ve {
        constructor(e) {
          if (this[he] = void 0, this[pe] = void 0, !e && !(e = this.get)) throw Error("Unknown getter");
          be(this, e);
        }
      }
      const be = (e, t) => ke(e, he, t);
      function _e(e, t) {
        if (e[he]) {
          let n = e[pe];
          n || ke(e, pe, n = new Set()), n.has(t) || (n.add(t), e.observerAdded && e.observerAdded(n.size, t));
        }
        return t;
      }
      function we(e, t) {
        let n = e[pe];
        if (n && n.has(t)) {
          const s = n.size - 1;
          s ? n.delete(t) : e[pe] = null, e.observerRemoved && e.observerRemoved(s, t);
        }
      }
      const ke = (e, t, n) => Object.defineProperty(e, t, {
          value: n,
          writable: !0,
          configurable: !0
        }),
        Pe = /[+\-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        Se = /(#(?:[0-9a-f]{2}){2,4}|(#[0-9a-f]{3})|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d\.]+%?\))/gi,
        xe = new RegExp(`(${Pe.source})(%|[a-z]+)`, "i"),
        Ve = /rgba\(([0-9\.-]+), ([0-9\.-]+), ([0-9\.-]+), ([0-9\.-]+)\)/gi,
        Oe = /var\((--[a-zA-Z0-9-_]+),? ?([a-zA-Z0-9 ()%#.,-]+)?\)/,
        Ce = e => {
          const [t, n] = Ae(e);
          if (!t || I()) return e;
          const s = window.getComputedStyle(document.documentElement).getPropertyValue(t);
          if (s) return s.trim();
          if (n && n.startsWith("--")) {
            return window.getComputedStyle(document.documentElement).getPropertyValue(n) || e;
          }
          return n && Oe.test(n) ? Ce(n) : n || e;
        },
        Ae = e => {
          const t = Oe.exec(e);
          if (!t) return [,];
          const [, n, s] = t;
          return [n, s];
        };
      let Ie;
      const je = (e, t, n, s, r) => `rgba(${Math.round(t)}, ${Math.round(n)}, ${Math.round(s)}, ${r})`,
        Re = e => {
          Ie || (Ie = M ? new RegExp(`(${Object.keys(M).join("|")})(?!\\w)`, "g") : /^\b$/);
          const t = e.output.map(e => me(e).replace(Oe, Ce).replace(Se, le).replace(Ie, le)),
            n = t.map(e => e.match(Pe).map(Number)),
            s = n[0].map((e, t) => n.map(e => {
              if (!(t in e)) throw Error("The arity of each \"output\" value must be equal");
              return e[t];
            })).map(t => ce(de({}, e, {
              output: t
            })));
          return e => {
            var n;
            const r = !xe.test(t[0]) && (null == (n = t.find(e => xe.test(e))) ? void 0 : n.replace(Pe, ""));
            let i = 0;
            return t[0].replace(Pe, () => `${s[i++](e)}${r || ""}`).replace(Ve, je);
          };
        },
        Me = "react-spring: ",
        Ee = e => {
          const t = e;
          let n = !1;
          if ("function" != typeof t) throw new TypeError(`${Me}once requires a function parameter`);
          return (...e) => {
            n || (t(...e), n = !0);
          };
        },
        qe = Ee(console.warn),
        Ne = Ee(console.warn);
      function Te(e) {
        return P.str(e) && ("#" == e[0] || /\d/.test(e) || !I() && Oe.test(e) || e in (M || {}));
      }
      const ze = "undefined" != typeof window && window.document && window.document.createElement ? e.useLayoutEffect : e.useEffect;
      function $e() {
        const t = (0, e.useState)()[1],
          n = (() => {
            const t = (0, e.useRef)(!1);
            return ze(() => (t.current = !0, () => {
              t.current = !1;
            }), []), t;
          })();
        return () => {
          n.current && t(Math.random());
        };
      }
      const Fe = t => (0, e.useEffect)(t, Ue),
        Ue = [];
      function De(t) {
        const n = (0, e.useRef)();
        return (0, e.useEffect)(() => {
          n.current = t;
        }), n.current;
      }
      const Le = Symbol.for("Animated:node"),
        Qe = e => e && e[Le],
        We = (e, t) => {
          return n = e, s = Le, r = t, Object.defineProperty(n, s, {
            value: r,
            writable: !0,
            configurable: !0
          });
          var n, s, r;
        },
        Ge = e => e && e[Le] && e[Le].getPayload();
      class Be {
        constructor() {
          this.payload = void 0, We(this, this);
        }
        getPayload() {
          return this.payload || [];
        }
      }
      class Ye extends Be {
        constructor(e) {
          super(), this.done = !0, this.elapsedTime = void 0, this.lastPosition = void 0, this.lastVelocity = void 0, this.v0 = void 0, this.durationProgress = 0, this._value = e, P.num(this._value) && (this.lastPosition = this._value);
        }
        static create(e) {
          return new Ye(e);
        }
        getPayload() {
          return [this];
        }
        getValue() {
          return this._value;
        }
        setValue(e, t) {
          return P.num(e) && (this.lastPosition = e, t && (e = Math.round(e / t) * t, this.done && (this.lastPosition = e))), this._value !== e && (this._value = e, !0);
        }
        reset() {
          const {
            done: e
          } = this;
          this.done = !1, P.num(this._value) && (this.elapsedTime = 0, this.durationProgress = 0, this.lastPosition = this._value, e && (this.lastVelocity = null), this.v0 = null);
        }
      }
      class Ze extends Ye {
        constructor(e) {
          super(0), this._string = null, this._toString = void 0, this._toString = ce({
            output: [e, e]
          });
        }
        static create(e) {
          return new Ze(e);
        }
        getValue() {
          let e = this._string;
          return null == e ? this._string = this._toString(this._value) : e;
        }
        setValue(e) {
          if (P.str(e)) {
            if (e == this._string) return !1;
            this._string = e, this._value = 1;
          } else {
            if (!super.setValue(e)) return !1;
            this._string = null;
          }
          return !0;
        }
        reset(e) {
          e && (this._toString = ce({
            output: [this.getValue(), e]
          })), this._value = 0, super.reset();
        }
      }
      const He = {
        dependencies: null
      };
      class Je extends Be {
        constructor(e) {
          super(), this.source = e, this.setValue(e);
        }
        getValue(e) {
          const t = {};
          return V(this.source, (n, s) => {
            var r;
            (r = n) && r[Le] === r ? t[s] = n.getValue(e) : fe(n) ? t[s] = me(n) : e || (t[s] = n);
          }), t;
        }
        setValue(e) {
          this.source = e, this.payload = this._makePayload(e);
        }
        reset() {
          this.payload && x(this.payload, e => e.reset());
        }
        _makePayload(e) {
          if (e) {
            const t = new Set();
            return V(e, this._addToPayload, t), Array.from(t);
          }
        }
        _addToPayload(e) {
          He.dependencies && fe(e) && He.dependencies.add(e);
          const t = Ge(e);
          t && x(t, e => this.add(e));
        }
      }
      class Xe extends Je {
        constructor(e) {
          super(e);
        }
        static create(e) {
          return new Xe(e);
        }
        getValue() {
          return this.source.map(e => e.getValue());
        }
        setValue(e) {
          const t = this.getPayload();
          return e.length == t.length ? t.map((t, n) => t.setValue(e[n])).some(Boolean) : (super.setValue(e.map(Ke)), !0);
        }
      }
      function Ke(e) {
        return (Te(e) ? Ze : Ye).create(e);
      }
      function et(e) {
        const t = Qe(e);
        return t ? t.constructor : P.arr(e) ? Xe : Te(e) ? Ze : Ye;
      }
      function tt() {
        return (tt = Object.assign || function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var n = arguments[t];
            for (var s in n) Object.prototype.hasOwnProperty.call(n, s) && (e[s] = n[s]);
          }
          return e;
        }).apply(this || _global, arguments);
      }
      const nt = (t, n) => {
        const r = !P.fun(t) || t.prototype && t.prototype.isReactComponent;
        return (0, e.forwardRef)((i, o) => {
          const a = (0, e.useRef)(null),
            u = r && (0, e.useCallback)(e => {
              a.current = function (e, t) {
                return e && (P.fun(e) ? e(t) : e.current = t), t;
              }(o, e);
            }, [o]),
            [l, c] = function (e, t) {
              const n = new Set();
              return He.dependencies = n, e.style && (e = tt({}, e, {
                style: t.createAnimatedStyle(e.style)
              })), e = new Je(e), He.dependencies = null, [e, n];
            }(i, n),
            d = $e(),
            h = () => {
              const e = a.current;
              r && !e || !1 === (!!e && n.applyAnimatedValues(e, l.getValue(!0))) && d();
            },
            p = new st(h, c),
            f = (0, e.useRef)();
          ze(() => (f.current = p, x(c, e => _e(e, p)), () => {
            f.current && (x(f.current.deps, e => we(e, f.current)), s.cancel(f.current.update));
          })), (0, e.useEffect)(h, []), Fe(() => () => {
            const e = f.current;
            x(e.deps, t => we(t, e));
          });
          const m = n.getComponentProps(l.getValue());
          return e.createElement(t, tt({}, m, {
            ref: u
          }));
        });
      };
      class st {
        constructor(e, t) {
          this.update = e, this.deps = t;
        }
        eventObserved(e) {
          "change" == e.type && s.write(this.update);
        }
      }
      const rt = Symbol.for("AnimatedComponent"),
        it = e => P.str(e) ? e : e && P.str(e.displayName) ? e.displayName : P.fun(e) && e.name || null;
      function ot() {
        return (ot = Object.assign || function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var n = arguments[t];
            for (var s in n) Object.prototype.hasOwnProperty.call(n, s) && (e[s] = n[s]);
          }
          return e;
        }).apply(this || _global, arguments);
      }
      function at(e, ...t) {
        return P.fun(e) ? e(...t) : e;
      }
      const ut = (e, t) => !0 === e || !!(t && e && (P.fun(e) ? e(t) : O(e).includes(t))),
        lt = (e, t) => P.obj(e) ? t && e[t] : e,
        ct = (e, t) => !0 === e.default ? e[t] : e.default ? e.default[t] : void 0,
        dt = e => e,
        ht = (e, t = dt) => {
          let n = pt;
          e.default && !0 !== e.default && (e = e.default, n = Object.keys(e));
          const s = {};
          for (const r of n) {
            const n = t(e[r], r);
            P.und(n) || (s[r] = n);
          }
          return s;
        },
        pt = ["config", "onProps", "onStart", "onChange", "onPause", "onResume", "onRest"],
        ft = {
          config: 1,
          from: 1,
          to: 1,
          ref: 1,
          loop: 1,
          reset: 1,
          pause: 1,
          cancel: 1,
          reverse: 1,
          immediate: 1,
          default: 1,
          delay: 1,
          onProps: 1,
          onStart: 1,
          onChange: 1,
          onPause: 1,
          onResume: 1,
          onRest: 1,
          onResolve: 1,
          items: 1,
          trail: 1,
          sort: 1,
          expires: 1,
          initial: 1,
          enter: 1,
          update: 1,
          leave: 1,
          children: 1,
          onDestroyed: 1,
          keys: 1,
          callId: 1,
          parentId: 1
        };
      function mt(e) {
        const t = function (e) {
          const t = {};
          let n = 0;
          if (V(e, (e, s) => {
            ft[s] || (t[s] = e, n++);
          }), n) return t;
        }(e);
        if (t) {
          const n = {
            to: t
          };
          return V(e, (e, s) => s in t || (n[s] = e)), n;
        }
        return ot({}, e);
      }
      function gt(e) {
        return e = me(e), P.arr(e) ? e.map(gt) : Te(e) ? N.createStringInterpolator({
          range: [0, 1],
          output: [e, e]
        })(1) : e;
      }
      function yt(e) {
        for (const t in e) return !0;
        return !1;
      }
      function vt(e) {
        return P.fun(e) || P.arr(e) && P.obj(e[0]);
      }
      function bt(e, t) {
        var n;
        null == (n = e.ref) || n.delete(e), null == t || t.delete(e);
      }
      function _t(e, t) {
        var n;
        t && e.ref !== t && (null == (n = e.ref) || n.delete(e), t.add(e), e.ref = t);
      }
      Math.PI, Math.PI;
      const wt = ot({}, {
        tension: 170,
        friction: 26
      }, {
        mass: 1,
        damping: 1,
        easing: e => e,
        clamp: !1
      });
      class kt {
        constructor() {
          this.tension = void 0, this.friction = void 0, this.frequency = void 0, this.damping = void 0, this.mass = void 0, this.velocity = 0, this.restVelocity = void 0, this.precision = void 0, this.progress = void 0, this.duration = void 0, this.easing = void 0, this.clamp = void 0, this.bounce = void 0, this.decay = void 0, this.round = void 0, Object.assign(this, wt);
        }
      }
      function Pt(e, t) {
        if (P.und(t.decay)) {
          const n = !P.und(t.tension) || !P.und(t.friction);
          !n && P.und(t.frequency) && P.und(t.damping) && P.und(t.mass) || (e.duration = void 0, e.decay = void 0), n && (e.frequency = void 0);
        } else e.duration = void 0;
      }
      const St = [];
      class xt {
        constructor() {
          this.changed = !1, this.values = St, this.toValues = null, this.fromValues = St, this.to = void 0, this.from = void 0, this.config = new kt(), this.immediate = !1;
        }
      }
      function Vt(e, {
        key: t,
        props: n,
        defaultProps: r,
        state: i,
        actions: o
      }) {
        return new Promise((a, u) => {
          var l;
          let c,
            d,
            h = ut(null != (l = n.cancel) ? l : null == r ? void 0 : r.cancel, t);
          if (h) m();else {
            P.und(n.pause) || (i.paused = ut(n.pause, t));
            let e = null == r ? void 0 : r.pause;
            !0 !== e && (e = i.paused || ut(e, t)), c = at(n.delay || 0, t), e ? (i.resumeQueue.add(f), o.pause()) : (o.resume(), f());
          }
          function p() {
            i.resumeQueue.add(f), i.timeouts.delete(d), d.cancel(), c = d.time - s.now();
          }
          function f() {
            c > 0 && !N.skipAnimation ? (i.delayed = !0, d = s.setTimeout(m, c), i.pauseQueue.add(p), i.timeouts.add(d)) : m();
          }
          function m() {
            i.delayed && (i.delayed = !1), i.pauseQueue.delete(p), i.timeouts.delete(d), e <= (i.cancelId || 0) && (h = !0);
            try {
              o.start(ot({}, n, {
                callId: e,
                cancel: h
              }), a);
            } catch (e) {
              u(e);
            }
          }
        });
      }
      const Ot = (e, t) => 1 == t.length ? t[0] : t.some(e => e.cancelled) ? It(e.get()) : t.every(e => e.noop) ? Ct(e.get()) : At(e.get(), t.every(e => e.finished)),
        Ct = e => ({
          value: e,
          noop: !0,
          finished: !0,
          cancelled: !1
        }),
        At = (e, t, n = !1) => ({
          value: e,
          finished: t,
          cancelled: n
        }),
        It = e => ({
          value: e,
          cancelled: !0,
          finished: !1
        });
      function jt(e, t, n, r) {
        const {
            callId: i,
            parentId: o,
            onRest: a
          } = t,
          {
            asyncTo: u,
            promise: l
          } = n;
        return o || e !== u || t.reset ? n.promise = (async () => {
          n.asyncId = i, n.asyncTo = e;
          const c = ht(t, (e, t) => "onRest" === t ? void 0 : e);
          let d, h;
          const p = new Promise((e, t) => (d = e, h = t)),
            f = e => {
              const t = i <= (n.cancelId || 0) && It(r) || i !== n.asyncId && At(r, !1);
              if (t) throw e.result = t, h(e), e;
            },
            m = (e, t) => {
              const s = new Mt(),
                o = new Et();
              return (async () => {
                if (N.skipAnimation) throw Rt(n), o.result = At(r, !1), h(o), o;
                f(s);
                const a = P.obj(e) ? ot({}, e) : ot({}, t, {
                  to: e
                });
                a.parentId = i, V(c, (e, t) => {
                  P.und(a[t]) && (a[t] = e);
                });
                const u = await r.start(a);
                return f(s), n.paused && (await new Promise(e => {
                  n.resumeQueue.add(e);
                })), u;
              })();
            };
          let g;
          if (N.skipAnimation) return Rt(n), At(r, !1);
          try {
            let t;
            t = P.arr(e) ? (async e => {
              for (const t of e) await m(t);
            })(e) : Promise.resolve(e(m, r.stop.bind(r))), await Promise.all([t.then(d), p]), g = At(r.get(), !0, !1);
          } catch (e) {
            if (e instanceof Mt) g = e.result;else {
              if (!(e instanceof Et)) throw e;
              g = e.result;
            }
          } finally {
            i == n.asyncId && (n.asyncId = o, n.asyncTo = o ? u : void 0, n.promise = o ? l : void 0);
          }
          return P.fun(a) && s.batchedUpdates(() => {
            a(g, r, r.item);
          }), g;
        })() : l;
      }
      function Rt(e, t) {
        C(e.timeouts, e => e.cancel()), e.pauseQueue.clear(), e.resumeQueue.clear(), e.asyncId = e.asyncTo = e.promise = void 0, t && (e.cancelId = t);
      }
      class Mt extends Error {
        constructor() {
          super("An async animation has been interrupted. You see this error because you forgot to use `await` or `.catch(...)` on its returned promise."), this.result = void 0;
        }
      }
      class Et extends Error {
        constructor() {
          super("SkipAnimationSignal"), this.result = void 0;
        }
      }
      const qt = e => e instanceof Tt;
      let Nt = 1;
      class Tt extends ve {
        constructor(...e) {
          super(...e), this.id = Nt++, this.key = void 0, this._priority = 0;
        }
        get priority() {
          return this._priority;
        }
        set priority(e) {
          this._priority != e && (this._priority = e, this._onPriorityChange(e));
        }
        get() {
          const e = Qe(this);
          return e && e.getValue();
        }
        to(...e) {
          return N.to(this, e);
        }
        interpolate(...e) {
          return qe(`${Me}The "interpolate" function is deprecated in v9 (use "to" instead)`), N.to(this, e);
        }
        toJSON() {
          return this.get();
        }
        observerAdded(e) {
          1 == e && this._attach();
        }
        observerRemoved(e) {
          0 == e && this._detach();
        }
        _attach() {}
        _detach() {}
        _onChange(e, t = !1) {
          ye(this, {
            type: "change",
            parent: this,
            value: e,
            idle: t
          });
        }
        _onPriorityChange(e) {
          this.idle || U.sort(this), ye(this, {
            type: "priority",
            parent: this,
            priority: e
          });
        }
      }
      const zt = Symbol.for("SpringPhase"),
        $t = e => (1 & e[zt]) > 0,
        Ft = e => (2 & e[zt]) > 0,
        Ut = e => (4 & e[zt]) > 0,
        Dt = (e, t) => t ? e[zt] |= 3 : e[zt] &= -3,
        Lt = (e, t) => t ? e[zt] |= 4 : e[zt] &= -5;
      class Qt extends Tt {
        constructor(e, t) {
          if (super(), this.key = void 0, this.animation = new xt(), this.queue = void 0, this.defaultProps = {}, this._state = {
            paused: !1,
            delayed: !1,
            pauseQueue: new Set(),
            resumeQueue: new Set(),
            timeouts: new Set()
          }, this._pendingCalls = new Set(), this._lastCallId = 0, this._lastToId = 0, this._memoizedDuration = 0, !P.und(e) || !P.und(t)) {
            const n = P.obj(e) ? ot({}, e) : ot({}, t, {
              from: e
            });
            P.und(n.default) && (n.default = !0), this.start(n);
          }
        }
        get idle() {
          return !(Ft(this) || this._state.asyncTo) || Ut(this);
        }
        get goal() {
          return me(this.animation.to);
        }
        get velocity() {
          const e = Qe(this);
          return e instanceof Ye ? e.lastVelocity || 0 : e.getPayload().map(e => e.lastVelocity || 0);
        }
        get hasAnimated() {
          return $t(this);
        }
        get isAnimating() {
          return Ft(this);
        }
        get isPaused() {
          return Ut(this);
        }
        get isDelayed() {
          return this._state.delayed;
        }
        advance(e) {
          let t = !0,
            n = !1;
          const s = this.animation;
          let {
            config: r,
            toValues: i
          } = s;
          const o = Ge(s.to);
          !o && fe(s.to) && (i = O(me(s.to))), s.values.forEach((a, u) => {
            if (a.done) return;
            const l = a.constructor == Ze ? 1 : o ? o[u].lastPosition : i[u];
            let c = s.immediate,
              d = l;
            if (!c) {
              if (d = a.lastPosition, r.tension <= 0) return void (a.done = !0);
              let t = a.elapsedTime += e;
              const n = s.fromValues[u],
                i = null != a.v0 ? a.v0 : a.v0 = P.arr(r.velocity) ? r.velocity[u] : r.velocity;
              let o;
              if (P.und(r.duration)) {
                if (r.decay) {
                  const e = !0 === r.decay ? 0.998 : r.decay,
                    s = Math.exp(-(1 - e) * t);
                  d = n + i / (1 - e) * (1 - s), c = Math.abs(a.lastPosition - d) < 0.1, o = i * s;
                } else {
                  o = null == a.lastVelocity ? i : a.lastVelocity;
                  const t = r.precision || (n == l ? 0.005 : Math.min(1, 0.001 * Math.abs(l - n))),
                    s = r.restVelocity || t / 10,
                    u = r.clamp ? 0 : r.bounce,
                    h = !P.und(u),
                    p = n == l ? a.v0 > 0 : n < l;
                  let f,
                    m = !1;
                  const g = 1,
                    y = Math.ceil(e / g);
                  for (let e = 0; e < y && (f = Math.abs(o) > s, f || (c = Math.abs(l - d) <= t, !c)); ++e) h && (m = d == l || d > l == p, m && (o = -o * u, d = l)), o += (0.000001 * -r.tension * (d - l) + 0.001 * -r.friction * o) / r.mass * g, d += o * g;
                }
              } else {
                let s = 1;
                r.duration > 0 && (this._memoizedDuration !== r.duration && (this._memoizedDuration = r.duration, a.durationProgress > 0 && (a.elapsedTime = r.duration * a.durationProgress, t = a.elapsedTime += e)), s = (r.progress || 0) + t / this._memoizedDuration, s = s > 1 ? 1 : s < 0 ? 0 : s, a.durationProgress = s), d = n + r.easing(s) * (l - n), o = (d - a.lastPosition) / e, c = 1 == s;
              }
              a.lastVelocity = o, Number.isNaN(d) && (console.warn("Got NaN while animating:", this), c = !0);
            }
            o && !o[u].done && (c = !1), c ? a.done = !0 : t = !1, a.setValue(d, r.round) && (n = !0);
          });
          const a = Qe(this),
            u = a.getValue();
          if (t) {
            const e = me(s.to);
            u === e && !n || r.decay ? n && r.decay && this._onChange(u) : (a.setValue(e), this._onChange(e)), this._stop();
          } else n && this._onChange(u);
        }
        set(e) {
          return s.batchedUpdates(() => {
            this._stop(), this._focus(e), this._set(e);
          }), this;
        }
        pause() {
          this._update({
            pause: !0
          });
        }
        resume() {
          this._update({
            pause: !1
          });
        }
        finish() {
          if (Ft(this)) {
            const {
              to: e,
              config: t
            } = this.animation;
            s.batchedUpdates(() => {
              this._onStart(), t.decay || this._set(e, !1), this._stop();
            });
          }
          return this;
        }
        update(e) {
          return (this.queue || (this.queue = [])).push(e), this;
        }
        start(e, t) {
          let n;
          return P.und(e) ? (n = this.queue || [], this.queue = []) : n = [P.obj(e) ? e : ot({}, t, {
            to: e
          })], Promise.all(n.map(e => this._update(e))).then(e => Ot(this, e));
        }
        stop(e) {
          const {
            to: t
          } = this.animation;
          return this._focus(this.get()), Rt(this._state, e && this._lastCallId), s.batchedUpdates(() => this._stop(t, e)), this;
        }
        reset() {
          this._update({
            reset: !0
          });
        }
        eventObserved(e) {
          "change" == e.type ? this._start() : "priority" == e.type && (this.priority = e.priority + 1);
        }
        _prepareNode(e) {
          const t = this.key || "";
          let {
            to: n,
            from: s
          } = e;
          n = P.obj(n) ? n[t] : n, (null == n || vt(n)) && (n = void 0), s = P.obj(s) ? s[t] : s, null == s && (s = void 0);
          const r = {
            to: n,
            from: s
          };
          return $t(this) || (e.reverse && ([n, s] = [s, n]), s = me(s), P.und(s) ? Qe(this) || this._set(n) : this._set(s)), r;
        }
        _update(e, t) {
          let n = ot({}, e);
          const {
            key: s,
            defaultProps: r
          } = this;
          n.default && Object.assign(r, ht(n, (e, t) => /^on/.test(t) ? lt(e, s) : e)), Jt(this, n, "onProps"), Xt(this, "onProps", n, this);
          const i = this._prepareNode(n);
          if (Object.isFrozen(this)) throw Error("Cannot animate a `SpringValue` object that is frozen. Did you forget to pass your component to `animated(...)` before animating its props?");
          const o = this._state;
          return Vt(++this._lastCallId, {
            key: s,
            props: n,
            defaultProps: r,
            state: o,
            actions: {
              pause: () => {
                Ut(this) || (Lt(this, !0), A(o.pauseQueue), Xt(this, "onPause", At(this, Wt(this, this.animation.to)), this));
              },
              resume: () => {
                Ut(this) && (Lt(this, !1), Ft(this) && this._resume(), A(o.resumeQueue), Xt(this, "onResume", At(this, Wt(this, this.animation.to)), this));
              },
              start: this._merge.bind(this, i)
            }
          }).then(e => {
            if (n.loop && e.finished && (!t || !e.noop)) {
              const e = Gt(n);
              if (e) return this._update(e, !0);
            }
            return e;
          });
        }
        _merge(e, t, n) {
          if (t.cancel) return this.stop(!0), n(It(this));
          const r = !P.und(e.to),
            i = !P.und(e.from);
          if (r || i) {
            if (!(t.callId > this._lastToId)) return n(It(this));
            this._lastToId = t.callId;
          }
          const {
              key: o,
              defaultProps: a,
              animation: u
            } = this,
            {
              to: l,
              from: c
            } = u;
          let {
            to: d = l,
            from: h = c
          } = e;
          !i || r || t.default && !P.und(d) || (d = h), t.reverse && ([d, h] = [h, d]);
          const p = !S(h, c);
          p && (u.from = h), h = me(h);
          const f = !S(d, l);
          f && this._focus(d);
          const m = vt(t.to),
            {
              config: g
            } = u,
            {
              decay: y,
              velocity: v
            } = g;
          (r || i) && (g.velocity = 0), t.config && !m && function (e, t, n) {
            n && (Pt(n = ot({}, n), t), t = ot({}, n, t)), Pt(e, t), Object.assign(e, t);
            for (const t in wt) null == e[t] && (e[t] = wt[t]);
            let {
              mass: s,
              frequency: r,
              damping: i
            } = e;
            P.und(r) || (r < 0.01 && (r = 0.01), i < 0 && (i = 0), e.tension = Math.pow(2 * Math.PI / r, 2) * s, e.friction = 4 * Math.PI * i * s / r);
          }(g, at(t.config, o), t.config !== a.config ? at(a.config, o) : void 0);
          let b = Qe(this);
          if (!b || P.und(d)) return n(At(this, !0));
          const _ = P.und(t.reset) ? i && !t.default : !P.und(h) && ut(t.reset, o),
            w = _ ? h : this.get(),
            k = gt(d),
            V = P.num(k) || P.arr(k) || Te(k),
            C = !m && (!V || ut(a.immediate || t.immediate, o));
          if (f) {
            const e = et(d);
            if (e !== b.constructor) {
              if (!C) throw Error(`Cannot animate between ${b.constructor.name} and ${e.name}, as the "to" prop suggests`);
              b = this._set(k);
            }
          }
          const I = b.constructor;
          let j = fe(d),
            R = !1;
          if (!j) {
            const e = _ || !$t(this) && p;
            (f || e) && (R = S(gt(w), k), j = !R), (S(u.immediate, C) || C) && S(g.decay, y) && S(g.velocity, v) || (j = !0);
          }
          if (R && Ft(this) && (u.changed && !_ ? j = !0 : j || this._stop(l)), !m && ((j || fe(l)) && (u.values = b.getPayload(), u.toValues = fe(d) ? null : I == Ze ? [1] : O(k)), u.immediate != C && (u.immediate = C, C || _ || this._set(l)), j)) {
            const {
              onRest: e
            } = u;
            x(Ht, e => Jt(this, t, e));
            const r = At(this, Wt(this, l));
            A(this._pendingCalls, r), this._pendingCalls.add(n), u.changed && s.batchedUpdates(() => {
              u.changed = !_, null == e || e(r, this), _ ? at(a.onRest, r) : null == u.onStart || u.onStart(r, this);
            });
          }
          _ && this._set(w), m ? n(jt(t.to, t, this._state, this)) : j ? this._start() : Ft(this) && !f ? this._pendingCalls.add(n) : n(Ct(w));
        }
        _focus(e) {
          const t = this.animation;
          e !== t.to && (ge(this) && this._detach(), t.to = e, ge(this) && this._attach());
        }
        _attach() {
          let e = 0;
          const {
            to: t
          } = this.animation;
          fe(t) && (_e(t, this), qt(t) && (e = t.priority + 1)), this.priority = e;
        }
        _detach() {
          const {
            to: e
          } = this.animation;
          fe(e) && we(e, this);
        }
        _set(e, t = !0) {
          const n = me(e);
          if (!P.und(n)) {
            const e = Qe(this);
            if (!e || !S(n, e.getValue())) {
              const r = et(n);
              e && e.constructor == r ? e.setValue(n) : We(this, r.create(n)), e && s.batchedUpdates(() => {
                this._onChange(n, t);
              });
            }
          }
          return Qe(this);
        }
        _onStart() {
          const e = this.animation;
          e.changed || (e.changed = !0, Xt(this, "onStart", At(this, Wt(this, e.to)), this));
        }
        _onChange(e, t) {
          t || (this._onStart(), at(this.animation.onChange, e, this)), at(this.defaultProps.onChange, e, this), super._onChange(e, t);
        }
        _start() {
          const e = this.animation;
          Qe(this).reset(me(e.to)), e.immediate || (e.fromValues = e.values.map(e => e.lastPosition)), Ft(this) || (Dt(this, !0), Ut(this) || this._resume());
        }
        _resume() {
          N.skipAnimation ? this.finish() : U.start(this);
        }
        _stop(e, t) {
          if (Ft(this)) {
            Dt(this, !1);
            const n = this.animation;
            x(n.values, e => {
              e.done = !0;
            }), n.toValues && (n.onChange = n.onPause = n.onResume = void 0), ye(this, {
              type: "idle",
              parent: this
            });
            const s = t ? It(this.get()) : At(this.get(), Wt(this, null != e ? e : n.to));
            A(this._pendingCalls, s), n.changed && (n.changed = !1, Xt(this, "onRest", s, this));
          }
        }
      }
      function Wt(e, t) {
        const n = gt(t);
        return S(gt(e.get()), n);
      }
      function Gt(e, t = e.loop, n = e.to) {
        let s = at(t);
        if (s) {
          const r = !0 !== s && mt(s),
            i = (r || e).reverse,
            o = !r || r.reset;
          return Bt(ot({}, e, {
            loop: t,
            default: !1,
            pause: void 0,
            to: !i || vt(n) ? n : void 0,
            from: o ? e.from : void 0,
            reset: o
          }, r));
        }
      }
      function Bt(e) {
        const {
            to: t,
            from: n
          } = e = mt(e),
          s = new Set();
        return P.obj(t) && Zt(t, s), P.obj(n) && Zt(n, s), e.keys = s.size ? Array.from(s) : null, e;
      }
      function Yt(e) {
        const t = Bt(e);
        return P.und(t.default) && (t.default = ht(t)), t;
      }
      function Zt(e, t) {
        V(e, (e, n) => null != e && t.add(n));
      }
      const Ht = ["onStart", "onRest", "onChange", "onPause", "onResume"];
      function Jt(e, t, n) {
        e.animation[n] = t[n] !== ct(t, n) ? lt(t[n], e.key) : void 0;
      }
      function Xt(e, t, ...n) {
        var s, r, i, o;
        null == (s = (r = e.animation)[t]) || s.call(r, ...n), null == (i = (o = e.defaultProps)[t]) || i.call(o, ...n);
      }
      const Kt = ["onStart", "onChange", "onRest"];
      let en = 1;
      class tn {
        constructor(e, t) {
          this.id = en++, this.springs = {}, this.queue = [], this.ref = void 0, this._flush = void 0, this._initialProps = void 0, this._lastAsyncId = 0, this._active = new Set(), this._changed = new Set(), this._started = !1, this._item = void 0, this._state = {
            paused: !1,
            pauseQueue: new Set(),
            resumeQueue: new Set(),
            timeouts: new Set()
          }, this._events = {
            onStart: new Map(),
            onChange: new Map(),
            onRest: new Map()
          }, this._onFrame = this._onFrame.bind(this), t && (this._flush = t), e && this.start(ot({
            default: !0
          }, e));
        }
        get idle() {
          return !this._state.asyncTo && Object.values(this.springs).every(e => e.idle && !e.isDelayed && !e.isPaused);
        }
        get item() {
          return this._item;
        }
        set item(e) {
          this._item = e;
        }
        get() {
          const e = {};
          return this.each((t, n) => e[n] = t.get()), e;
        }
        set(e) {
          for (const t in e) {
            const n = e[t];
            P.und(n) || this.springs[t].set(n);
          }
        }
        update(e) {
          return e && this.queue.push(Bt(e)), this;
        }
        start(e) {
          let {
            queue: t
          } = this;
          return e ? t = O(e).map(Bt) : this.queue = [], this._flush ? this._flush(this, t) : (ln(this, t), nn(this, t));
        }
        stop(e, t) {
          if (e !== !!e && (t = e), t) {
            const n = this.springs;
            x(O(t), t => n[t].stop(!!e));
          } else Rt(this._state, this._lastAsyncId), this.each(t => t.stop(!!e));
          return this;
        }
        pause(e) {
          if (P.und(e)) this.start({
            pause: !0
          });else {
            const t = this.springs;
            x(O(e), e => t[e].pause());
          }
          return this;
        }
        resume(e) {
          if (P.und(e)) this.start({
            pause: !1
          });else {
            const t = this.springs;
            x(O(e), e => t[e].resume());
          }
          return this;
        }
        each(e) {
          V(this.springs, e);
        }
        _onFrame() {
          const {
              onStart: e,
              onChange: t,
              onRest: n
            } = this._events,
            s = this._active.size > 0,
            r = this._changed.size > 0;
          (s && !this._started || r && !this._started) && (this._started = !0, C(e, ([e, t]) => {
            t.value = this.get(), e(t, this, this._item);
          }));
          const i = !s && this._started,
            o = r || i && n.size ? this.get() : null;
          r && t.size && C(t, ([e, t]) => {
            t.value = o, e(t, this, this._item);
          }), i && (this._started = !1, C(n, ([e, t]) => {
            t.value = o, e(t, this, this._item);
          }));
        }
        eventObserved(e) {
          if ("change" == e.type) this._changed.add(e.parent), e.idle || this._active.add(e.parent);else {
            if ("idle" != e.type) return;
            this._active.delete(e.parent);
          }
          s.onFrame(this._onFrame);
        }
      }
      function nn(e, t) {
        return Promise.all(t.map(t => sn(e, t))).then(t => Ot(e, t));
      }
      async function sn(e, t, n) {
        const {
            keys: r,
            to: i,
            from: o,
            loop: a,
            onRest: u,
            onResolve: l
          } = t,
          c = P.obj(t.default) && t.default;
        a && (t.loop = !1), !1 === i && (t.to = null), !1 === o && (t.from = null);
        const d = P.arr(i) || P.fun(i) ? i : void 0;
        d ? (t.to = void 0, t.onRest = void 0, c && (c.onRest = void 0)) : x(Kt, n => {
          const s = t[n];
          if (P.fun(s)) {
            const r = e._events[n];
            t[n] = ({
              finished: e,
              cancelled: t
            }) => {
              const n = r.get(s);
              n ? (e || (n.finished = !1), t && (n.cancelled = !0)) : r.set(s, {
                value: null,
                finished: e || !1,
                cancelled: t || !1
              });
            }, c && (c[n] = t[n]);
          }
        });
        const h = e._state;
        t.pause === !h.paused ? (h.paused = t.pause, A(t.pause ? h.pauseQueue : h.resumeQueue)) : h.paused && (t.pause = !0);
        const p = (r || Object.keys(e.springs)).map(n => e.springs[n].start(t)),
          f = !0 === t.cancel || !0 === ct(t, "cancel");
        (d || f && h.asyncId) && p.push(Vt(++e._lastAsyncId, {
          props: t,
          state: h,
          actions: {
            pause: k,
            resume: k,
            start(t, n) {
              f ? (Rt(h, e._lastAsyncId), n(It(e))) : (t.onRest = u, n(jt(d, t, h, e)));
            }
          }
        })), h.paused && (await new Promise(e => {
          h.resumeQueue.add(e);
        }));
        const m = Ot(e, await Promise.all(p));
        if (a && m.finished && (!n || !m.noop)) {
          const n = Gt(t, a, i);
          if (n) return ln(e, [n]), sn(e, n, !0);
        }
        return l && s.batchedUpdates(() => l(m, e, e.item)), m;
      }
      function rn(e, t) {
        const n = ot({}, e.springs);
        return t && x(O(t), e => {
          P.und(e.keys) && (e = Bt(e)), P.obj(e.to) || (e = ot({}, e, {
            to: void 0
          })), un(n, e, e => an(e));
        }), on(e, n), n;
      }
      function on(e, t) {
        V(t, (t, n) => {
          e.springs[n] || (e.springs[n] = t, _e(t, e));
        });
      }
      function an(e, t) {
        const n = new Qt();
        return n.key = e, t && _e(n, t), n;
      }
      function un(e, t, n) {
        t.keys && x(t.keys, s => {
          (e[s] || (e[s] = n(s)))._prepareNode(t);
        });
      }
      function ln(e, t) {
        x(t, t => {
          un(e.springs, t, t => an(t, e));
        });
      }
      function cn(e, t) {
        if (null == e) return {};
        var n,
          s,
          r = {},
          i = Object.keys(e);
        for (s = 0; s < i.length; s++) n = i[s], t.indexOf(n) >= 0 || (r[n] = e[n]);
        return r;
      }
      const dn = ["children"],
        hn = t => {
          let {
              children: n
            } = t,
            s = cn(t, dn);
          const r = (0, e.useContext)(pn),
            i = s.pause || !!r.pause,
            o = s.immediate || !!r.immediate;
          s = function (t, n) {
            const [s] = (0, e.useState)(() => ({
                inputs: n,
                result: t()
              })),
              r = (0, e.useRef)(),
              i = r.current;
            let o = i;
            return o ? Boolean(n && o.inputs && function (e, t) {
              if (e.length !== t.length) return !1;
              for (let n = 0; n < e.length; n++) if (e[n] !== t[n]) return !1;
              return !0;
            }(n, o.inputs)) || (o = {
              inputs: n,
              result: t()
            }) : o = s, (0, e.useEffect)(() => {
              r.current = o, i == s && (s.inputs = s.result = void 0);
            }, [o]), o.result;
          }(() => ({
            pause: i,
            immediate: o
          }), [i, o]);
          const {
            Provider: a
          } = pn;
          return e.createElement(a, {
            value: s
          }, n);
        },
        pn = (fn = hn, mn = {}, Object.assign(fn, e.createContext(mn)), fn.Provider._context = fn, fn.Consumer._context = fn, fn);
      var fn, mn;
      hn.Provider = pn.Provider, hn.Consumer = pn.Consumer;
      const gn = () => {
        const e = [],
          t = function (t) {
            Ne(`${Me}Directly calling start instead of using the api object is deprecated in v9 (use ".start" instead), this will be removed in later 0.X.0 versions`);
            const s = [];
            return x(e, (e, r) => {
              if (P.und(t)) s.push(e.start());else {
                const i = n(t, e, r);
                i && s.push(e.start(i));
              }
            }), s;
          };
        t.current = e, t.add = function (t) {
          e.includes(t) || e.push(t);
        }, t.delete = function (t) {
          const n = e.indexOf(t);
          ~n && e.splice(n, 1);
        }, t.pause = function () {
          return x(e, e => e.pause(...arguments)), this || _global;
        }, t.resume = function () {
          return x(e, e => e.resume(...arguments)), this || _global;
        }, t.set = function (t) {
          x(e, e => e.set(t));
        }, t.start = function (t) {
          const n = [];
          return x(e, (e, s) => {
            if (P.und(t)) n.push(e.start());else {
              const r = this._getProps(t, e, s);
              r && n.push(e.start(r));
            }
          }), n;
        }, t.stop = function () {
          return x(e, e => e.stop(...arguments)), this || _global;
        }, t.update = function (t) {
          return x(e, (e, n) => e.update(this._getProps(t, e, n))), this || _global;
        };
        const n = function (e, t, n) {
          return P.fun(e) ? e(n, t) : e;
        };
        return t._getProps = n, t;
      };
      function yn(t, n, s) {
        const r = P.fun(n) && n;
        r && !s && (s = []);
        const i = (0, e.useMemo)(() => r || 3 == arguments.length ? gn() : void 0, []),
          o = (0, e.useRef)(0),
          a = $e(),
          u = (0, e.useMemo)(() => ({
            ctrls: [],
            queue: [],
            flush(e, t) {
              const n = rn(e, t);
              return o.current > 0 && !u.queue.length && !Object.keys(n).some(t => !e.springs[t]) ? nn(e, t) : new Promise(s => {
                on(e, n), u.queue.push(() => {
                  s(nn(e, t));
                }), a();
              });
            }
          }), []),
          l = (0, e.useRef)([...u.ctrls]),
          c = [],
          d = De(t) || 0;
        function h(e, t) {
          for (let s = e; s < t; s++) {
            const e = l.current[s] || (l.current[s] = new tn(null, u.flush)),
              t = r ? r(s, e) : n[s];
            t && (c[s] = Yt(t));
          }
        }
        (0, e.useMemo)(() => {
          x(l.current.slice(t, d), e => {
            bt(e, i), e.stop(!0);
          }), l.current.length = t, h(d, t);
        }, [t]), (0, e.useMemo)(() => {
          h(0, Math.min(d, t));
        }, s);
        const p = l.current.map((e, t) => rn(e, c[t])),
          f = (0, e.useContext)(hn),
          m = De(f),
          g = f !== m && yt(f);
        ze(() => {
          o.current++, u.ctrls = l.current;
          const {
            queue: e
          } = u;
          e.length && (u.queue = [], x(e, e => e())), x(l.current, (e, t) => {
            null == i || i.add(e), g && e.start({
              default: f
            });
            const n = c[t];
            n && (_t(e, n.ref), e.ref ? e.queue.push(n) : e.start(n));
          });
        }), Fe(() => () => {
          x(u.ctrls, e => e.stop(!0));
        });
        const y = p.map(e => ot({}, e));
        return i ? [y, i] : y;
      }
      let vn;
      !function (e) {
        e.MOUNT = "mount", e.ENTER = "enter", e.UPDATE = "update", e.LEAVE = "leave";
      }(vn || (vn = {}));
      const bn = ["children"];
      function _n(e) {
        let {
          children: t
        } = e;
        return t(function (e, t) {
          const n = P.fun(e),
            [[s], r] = yn(1, n ? e : [e], n ? t || [] : t);
          return n || 2 == arguments.length ? [s, r] : s;
        }(cn(e, bn)));
      }
      class wn extends Tt {
        constructor(e, t) {
          super(), this.key = void 0, this.idle = !0, this.calc = void 0, this._active = new Set(), this.source = e, this.calc = ce(...t);
          const n = this._get(),
            s = et(n);
          We(this, s.create(n));
        }
        advance(e) {
          const t = this._get();
          S(t, this.get()) || (Qe(this).setValue(t), this._onChange(t, this.idle)), !this.idle && Pn(this._active) && Sn(this);
        }
        _get() {
          const e = P.arr(this.source) ? this.source.map(me) : O(me(this.source));
          return this.calc(...e);
        }
        _start() {
          this.idle && !Pn(this._active) && (this.idle = !1, x(Ge(this), e => {
            e.done = !1;
          }), N.skipAnimation ? (s.batchedUpdates(() => this.advance()), Sn(this)) : U.start(this));
        }
        _attach() {
          let e = 1;
          x(O(this.source), t => {
            fe(t) && _e(t, this), qt(t) && (t.idle || this._active.add(t), e = Math.max(e, t.priority + 1));
          }), this.priority = e, this._start();
        }
        _detach() {
          x(O(this.source), e => {
            fe(e) && we(e, this);
          }), this._active.clear(), Sn(this);
        }
        eventObserved(e) {
          "change" == e.type ? e.idle ? this.advance() : (this._active.add(e.parent), this._start()) : "idle" == e.type ? this._active.delete(e.parent) : "priority" == e.type && (this.priority = O(this.source).reduce((e, t) => Math.max(e, (qt(t) ? t.priority : 0) + 1), 0));
        }
      }
      function kn(e) {
        return !1 !== e.idle;
      }
      function Pn(e) {
        return !e.size || Array.from(e).every(kn);
      }
      function Sn(e) {
        e.idle || (e.idle = !0, x(Ge(e), e => {
          e.done = !0;
        }), ye(e, {
          type: "idle",
          parent: e
        }));
      }
      N.assign({
        createStringInterpolator: Re,
        to: (e, t) => new wn(e, t)
      }), U.advance;
      var xn = r(268);
      function Vn(e, t) {
        if (null == e) return {};
        var n,
          s,
          r = {},
          i = Object.keys(e);
        for (s = 0; s < i.length; s++) n = i[s], t.indexOf(n) >= 0 || (r[n] = e[n]);
        return r;
      }
      const On = ["style", "children", "scrollTop", "scrollLeft"],
        Cn = /^--/;
      function An(e, t) {
        return null == t || "boolean" == typeof t || "" === t ? "" : "number" != typeof t || 0 === t || Cn.test(e) || jn.hasOwnProperty(e) && jn[e] ? ("" + t).trim() : t + "px";
      }
      const In = {};
      let jn = {
        animationIterationCount: !0,
        borderImageOutset: !0,
        borderImageSlice: !0,
        borderImageWidth: !0,
        boxFlex: !0,
        boxFlexGroup: !0,
        boxOrdinalGroup: !0,
        columnCount: !0,
        columns: !0,
        flex: !0,
        flexGrow: !0,
        flexPositive: !0,
        flexShrink: !0,
        flexNegative: !0,
        flexOrder: !0,
        gridRow: !0,
        gridRowEnd: !0,
        gridRowSpan: !0,
        gridRowStart: !0,
        gridColumn: !0,
        gridColumnEnd: !0,
        gridColumnSpan: !0,
        gridColumnStart: !0,
        fontWeight: !0,
        lineClamp: !0,
        lineHeight: !0,
        opacity: !0,
        order: !0,
        orphans: !0,
        tabSize: !0,
        widows: !0,
        zIndex: !0,
        zoom: !0,
        fillOpacity: !0,
        floodOpacity: !0,
        stopOpacity: !0,
        strokeDasharray: !0,
        strokeDashoffset: !0,
        strokeMiterlimit: !0,
        strokeOpacity: !0,
        strokeWidth: !0
      };
      const Rn = ["Webkit", "Ms", "Moz", "O"];
      jn = Object.keys(jn).reduce((e, t) => (Rn.forEach(n => e[((e, t) => e + t.charAt(0).toUpperCase() + t.substring(1))(n, t)] = e[t]), e), jn);
      const Mn = ["x", "y", "z"],
        En = /^(matrix|translate|scale|rotate|skew)/,
        qn = /^(translate)/,
        Nn = /^(rotate|skew)/,
        Tn = (e, t) => P.num(e) && 0 !== e ? e + t : e,
        zn = (e, t) => P.arr(e) ? e.every(e => zn(e, t)) : P.num(e) ? e === t : parseFloat(e) === t;
      class $n extends Je {
        constructor(e) {
          let {
              x: t,
              y: n,
              z: s
            } = e,
            r = Vn(e, Mn);
          const i = [],
            o = [];
          (t || n || s) && (i.push([t || 0, n || 0, s || 0]), o.push(e => [`translate3d(${e.map(e => Tn(e, "px")).join(",")})`, zn(e, 0)])), V(r, (e, t) => {
            if ("transform" === t) i.push([e || ""]), o.push(e => [e, "" === e]);else if (En.test(t)) {
              if (delete r[t], P.und(e)) return;
              const n = qn.test(t) ? "px" : Nn.test(t) ? "deg" : "";
              i.push(O(e)), o.push("rotate3d" === t ? ([e, t, s, r]) => [`rotate3d(${e},${t},${s},${Tn(r, n)})`, zn(r, 0)] : e => [`${t}(${e.map(e => Tn(e, n)).join(",")})`, zn(e, t.startsWith("scale") ? 1 : 0)]);
            }
          }), i.length && (r.transform = new Fn(i, o)), super(r);
        }
      }
      class Fn extends ve {
        constructor(e, t) {
          super(), this._value = null, this.inputs = e, this.transforms = t;
        }
        get() {
          return this._value || (this._value = this._get());
        }
        _get() {
          let e = "",
            t = !0;
          return x(this.inputs, (n, s) => {
            const r = me(n[0]),
              [i, o] = this.transforms[s](P.arr(r) ? r : n.map(me));
            e += " " + i, t = t && o;
          }), t ? "none" : e;
        }
        observerAdded(e) {
          1 == e && x(this.inputs, e => x(e, e => fe(e) && _e(e, this)));
        }
        observerRemoved(e) {
          0 == e && x(this.inputs, e => x(e, e => fe(e) && we(e, this)));
        }
        eventObserved(e) {
          "change" == e.type && (this._value = null), ye(this, e);
        }
      }
      const Un = ["scrollTop", "scrollLeft"];
      N.assign({
        batchedUpdates: xn.unstable_batchedUpdates,
        createStringInterpolator: Re,
        colors: {
          transparent: 0,
          aliceblue: 4042850303,
          antiquewhite: 4209760255,
          aqua: 16777215,
          aquamarine: 2147472639,
          azure: 4043309055,
          beige: 4126530815,
          bisque: 4293182719,
          black: 255,
          blanchedalmond: 4293643775,
          blue: 65535,
          blueviolet: 2318131967,
          brown: 2771004159,
          burlywood: 3736635391,
          burntsienna: 3934150143,
          cadetblue: 1604231423,
          chartreuse: 2147418367,
          chocolate: 3530104575,
          coral: 4286533887,
          cornflowerblue: 1687547391,
          cornsilk: 4294499583,
          crimson: 3692313855,
          cyan: 16777215,
          darkblue: 35839,
          darkcyan: 9145343,
          darkgoldenrod: 3095792639,
          darkgray: 2846468607,
          darkgreen: 6553855,
          darkgrey: 2846468607,
          darkkhaki: 3182914559,
          darkmagenta: 2332068863,
          darkolivegreen: 1433087999,
          darkorange: 4287365375,
          darkorchid: 2570243327,
          darkred: 2332033279,
          darksalmon: 3918953215,
          darkseagreen: 2411499519,
          darkslateblue: 1211993087,
          darkslategray: 793726975,
          darkslategrey: 793726975,
          darkturquoise: 13554175,
          darkviolet: 2483082239,
          deeppink: 4279538687,
          deepskyblue: 12582911,
          dimgray: 1768516095,
          dimgrey: 1768516095,
          dodgerblue: 512819199,
          firebrick: 2988581631,
          floralwhite: 4294635775,
          forestgreen: 579543807,
          fuchsia: 4278255615,
          gainsboro: 3705462015,
          ghostwhite: 4177068031,
          gold: 4292280575,
          goldenrod: 3668254975,
          gray: 2155905279,
          green: 8388863,
          greenyellow: 2919182335,
          grey: 2155905279,
          honeydew: 4043305215,
          hotpink: 4285117695,
          indianred: 3445382399,
          indigo: 1258324735,
          ivory: 4294963455,
          khaki: 4041641215,
          lavender: 3873897215,
          lavenderblush: 4293981695,
          lawngreen: 2096890111,
          lemonchiffon: 4294626815,
          lightblue: 2916673279,
          lightcoral: 4034953471,
          lightcyan: 3774873599,
          lightgoldenrodyellow: 4210742015,
          lightgray: 3553874943,
          lightgreen: 2431553791,
          lightgrey: 3553874943,
          lightpink: 4290167295,
          lightsalmon: 4288707327,
          lightseagreen: 548580095,
          lightskyblue: 2278488831,
          lightslategray: 2005441023,
          lightslategrey: 2005441023,
          lightsteelblue: 2965692159,
          lightyellow: 4294959359,
          lime: 16711935,
          limegreen: 852308735,
          linen: 4210091775,
          magenta: 4278255615,
          maroon: 2147483903,
          mediumaquamarine: 1724754687,
          mediumblue: 52735,
          mediumorchid: 3126187007,
          mediumpurple: 2473647103,
          mediumseagreen: 1018393087,
          mediumslateblue: 2070474495,
          mediumspringgreen: 16423679,
          mediumturquoise: 1221709055,
          mediumvioletred: 3340076543,
          midnightblue: 421097727,
          mintcream: 4127193855,
          mistyrose: 4293190143,
          moccasin: 4293178879,
          navajowhite: 4292783615,
          navy: 33023,
          oldlace: 4260751103,
          olive: 2155872511,
          olivedrab: 1804477439,
          orange: 4289003775,
          orangered: 4282712319,
          orchid: 3664828159,
          palegoldenrod: 4008225535,
          palegreen: 2566625535,
          paleturquoise: 2951671551,
          palevioletred: 3681588223,
          papayawhip: 4293907967,
          peachpuff: 4292524543,
          peru: 3448061951,
          pink: 4290825215,
          plum: 3718307327,
          powderblue: 2967529215,
          purple: 2147516671,
          rebeccapurple: 1714657791,
          red: 4278190335,
          rosybrown: 3163525119,
          royalblue: 1097458175,
          saddlebrown: 2336560127,
          salmon: 4202722047,
          sandybrown: 4104413439,
          seagreen: 780883967,
          seashell: 4294307583,
          sienna: 2689740287,
          silver: 3233857791,
          skyblue: 2278484991,
          slateblue: 1784335871,
          slategray: 1887473919,
          slategrey: 1887473919,
          snow: 4294638335,
          springgreen: 16744447,
          steelblue: 1182971135,
          tan: 3535047935,
          teal: 8421631,
          thistle: 3636451583,
          tomato: 4284696575,
          turquoise: 1088475391,
          violet: 4001558271,
          wheat: 4125012991,
          white: 4294967295,
          whitesmoke: 4126537215,
          yellow: 4294902015,
          yellowgreen: 2597139199
        }
      });
      const Dn = ((e, {
        applyAnimatedValues: t = () => !1,
        createAnimatedStyle: n = e => new Je(e),
        getComponentProps: s = e => e
      } = {}) => {
        const r = {
            applyAnimatedValues: t,
            createAnimatedStyle: n,
            getComponentProps: s
          },
          i = e => {
            const t = it(e) || "Anonymous";
            return (e = P.str(e) ? i[e] || (i[e] = nt(e, r)) : e[rt] || (e[rt] = nt(e, r))).displayName = `Animated(${t})`, e;
          };
        return V(e, (t, n) => {
          P.arr(e) && (n = it(t)), i[n] = i(t);
        }), {
          animated: i
        };
      })(["a", "abbr", "address", "area", "article", "aside", "audio", "b", "base", "bdi", "bdo", "big", "blockquote", "body", "br", "button", "canvas", "caption", "cite", "code", "col", "colgroup", "data", "datalist", "dd", "del", "details", "dfn", "dialog", "div", "dl", "dt", "em", "embed", "fieldset", "figcaption", "figure", "footer", "form", "h1", "h2", "h3", "h4", "h5", "h6", "head", "header", "hgroup", "hr", "html", "i", "iframe", "img", "input", "ins", "kbd", "keygen", "label", "legend", "li", "link", "main", "map", "mark", "menu", "menuitem", "meta", "meter", "nav", "noscript", "object", "ol", "optgroup", "option", "output", "p", "param", "picture", "pre", "progress", "q", "rp", "rt", "ruby", "s", "samp", "script", "section", "select", "small", "source", "span", "strong", "style", "sub", "summary", "sup", "table", "tbody", "td", "textarea", "tfoot", "th", "thead", "time", "title", "tr", "track", "u", "ul", "var", "video", "wbr", "circle", "clipPath", "defs", "ellipse", "foreignObject", "g", "image", "line", "linearGradient", "mask", "path", "pattern", "polygon", "polyline", "radialGradient", "rect", "stop", "svg", "text", "tspan"], {
        applyAnimatedValues: function (e, t) {
          if (!e.nodeType || !e.setAttribute) return !1;
          const n = "filter" === e.nodeName || e.parentNode && "filter" === e.parentNode.nodeName,
            s = t,
            {
              style: r,
              children: i,
              scrollTop: o,
              scrollLeft: a
            } = s,
            u = Vn(s, On),
            l = Object.values(u),
            c = Object.keys(u).map(t => n || e.hasAttribute(t) ? t : In[t] || (In[t] = t.replace(/([A-Z])/g, e => "-" + e.toLowerCase())));
          void 0 !== i && (e.textContent = i);
          for (let t in r) if (r.hasOwnProperty(t)) {
            const n = An(t, r[t]);
            Cn.test(t) ? e.style.setProperty(t, n) : e.style[t] = n;
          }
          c.forEach((t, n) => {
            e.setAttribute(t, l[n]);
          }), void 0 !== o && (e.scrollTop = o), void 0 !== a && (e.scrollLeft = a);
        },
        createAnimatedStyle: e => new $n(e),
        getComponentProps: e => Vn(e, Un)
      }).animated;
      function Ln() {
        return (Ln = Object.assign || function (e) {
          for (var t = 1; t < arguments.length; t++) {
            var n = arguments[t];
            for (var s in n) Object.prototype.hasOwnProperty.call(n, s) && (e[s] = n[s]);
          }
          return e;
        }).apply(this || _global, arguments);
      }
      function Qn(e, t) {
        return (Qn = Object.setPrototypeOf || function (e, t) {
          return e.__proto__ = t, e;
        })(e, t);
      }
      var Wn = new Map(),
        Gn = new WeakMap(),
        Bn = 0;
      function Yn(e, t, n, s) {
        if (void 0 === n && (n = {}), void 0 === s && (s = undefined), void 0 === window.IntersectionObserver && void 0 !== s) {
          var r = e.getBoundingClientRect();
          return t(s, {
            isIntersecting: s,
            target: e,
            intersectionRatio: "number" == typeof n.threshold ? n.threshold : 0,
            time: 0,
            boundingClientRect: r,
            intersectionRect: r,
            rootBounds: r
          }), function () {};
        }
        var i = function (e) {
            var t = function (e) {
                return Object.keys(e).sort().filter(function (t) {
                  return void 0 !== e[t];
                }).map(function (t) {
                  return t + "_" + ("root" === t ? (n = e.root) ? (Gn.has(n) || (Bn += 1, Gn.set(n, Bn.toString())), Gn.get(n)) : "0" : e[t]);
                  var n;
                }).toString();
              }(e),
              n = Wn.get(t);
            if (!n) {
              var s,
                r = new Map(),
                i = new IntersectionObserver(function (t) {
                  t.forEach(function (t) {
                    var n,
                      i = t.isIntersecting && s.some(function (e) {
                        return t.intersectionRatio >= e;
                      });
                    e.trackVisibility && void 0 === t.isVisible && (t.isVisible = i), null == (n = r.get(t.target)) || n.forEach(function (e) {
                      e(i, t);
                    });
                  });
                }, e);
              s = i.thresholds || (Array.isArray(e.threshold) ? e.threshold : [e.threshold || 0]), n = {
                id: t,
                observer: i,
                elements: r
              }, Wn.set(t, n);
            }
            return n;
          }(n),
          o = i.id,
          a = i.observer,
          u = i.elements,
          l = u.get(e) || [];
        return u.has(e) || u.set(e, l), l.push(t), a.observe(e), function () {
          l.splice(l.indexOf(t), 1), 0 === l.length && (u.delete(e), a.unobserve(e)), 0 === u.size && (a.disconnect(), Wn.delete(o));
        };
      }
      var Zn = ["children", "as", "tag", "triggerOnce", "threshold", "root", "rootMargin", "onChange", "skip", "trackVisibility", "delay", "initialInView", "fallbackInView"];
      function Hn(e) {
        return "function" != typeof e.children;
      }
      var Jn = function (t) {
        var n, s;
        function r(e) {
          var n;
          return (n = t.call(this || _global, e) || this || _global).node = null, n._unobserveCb = null, n.handleNode = function (e) {
            n.node && (n.unobserve(), e || n.props.triggerOnce || n.props.skip || n.setState({
              inView: !!n.props.initialInView,
              entry: void 0
            })), n.node = e || null, n.observeNode();
          }, n.handleChange = function (e, t) {
            e && n.props.triggerOnce && n.unobserve(), Hn(n.props) || n.setState({
              inView: e,
              entry: t
            }), n.props.onChange && n.props.onChange(e, t);
          }, n.state = {
            inView: !!e.initialInView,
            entry: void 0
          }, n;
        }
        s = t, (n = r).prototype = Object.create(s.prototype), n.prototype.constructor = n, Qn(n, s);
        var i = r.prototype;
        return i.componentDidUpdate = function (e) {
          e.rootMargin === (this || _global).props.rootMargin && e.root === (this || _global).props.root && e.threshold === (this || _global).props.threshold && e.skip === (this || _global).props.skip && e.trackVisibility === (this || _global).props.trackVisibility && e.delay === (this || _global).props.delay || (this.unobserve(), this.observeNode());
        }, i.componentWillUnmount = function () {
          this.unobserve(), (this || _global).node = null;
        }, i.observeNode = function () {
          if ((this || _global).node && !(this || _global).props.skip) {
            var e = (this || _global).props,
              t = e.threshold,
              n = e.root,
              s = e.rootMargin,
              r = e.trackVisibility,
              i = e.delay,
              o = e.fallbackInView;
            (this || _global)._unobserveCb = Yn((this || _global).node, (this || _global).handleChange, {
              threshold: t,
              root: n,
              rootMargin: s,
              trackVisibility: r,
              delay: i
            }, o);
          }
        }, i.unobserve = function () {
          (this || _global)._unobserveCb && (this._unobserveCb(), (this || _global)._unobserveCb = null);
        }, i.render = function () {
          if (!Hn((this || _global).props)) {
            var t = (this || _global).state,
              n = t.inView,
              s = t.entry;
            return (this || _global).props.children({
              inView: n,
              entry: s,
              ref: (this || _global).handleNode
            });
          }
          var r = (this || _global).props,
            i = r.children,
            o = r.as,
            a = r.tag,
            u = function (e, t) {
              if (null == e) return {};
              var n,
                s,
                r = {},
                i = Object.keys(e);
              for (s = 0; s < i.length; s++) n = i[s], t.indexOf(n) >= 0 || (r[n] = e[n]);
              return r;
            }(r, Zn);
          return e.createElement(o || a || "div", Ln({
            ref: (this || _global).handleNode
          }, u), i);
        }, r;
      }(e.Component);
      Jn.displayName = "InView", Jn.defaultProps = {
        threshold: 0,
        triggerOnce: !1,
        initialInView: !1
      };
      const Xn = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        Kn = ({
          animateToNumber: n,
          fontStyle: s,
          configs: r,
          includeComma: i,
          locale: o
        }) => {
          const {
              ref: a,
              inView: u
            } = function (t) {
              var n = void 0 === t ? {} : t,
                s = n.threshold,
                r = n.delay,
                i = n.trackVisibility,
                o = n.rootMargin,
                a = n.root,
                u = n.triggerOnce,
                l = n.skip,
                c = n.initialInView,
                d = n.fallbackInView,
                h = e.useRef(),
                p = e.useState({
                  inView: !!c
                }),
                f = p[0],
                m = p[1],
                g = e.useCallback(function (e) {
                  void 0 !== h.current && (h.current(), h.current = void 0), l || e && (h.current = Yn(e, function (e, t) {
                    m({
                      inView: e,
                      entry: t
                    }), t.isIntersecting && u && h.current && (h.current(), h.current = void 0);
                  }, {
                    root: a,
                    rootMargin: o,
                    threshold: s,
                    trackVisibility: i,
                    delay: r
                  }, d));
                }, [Array.isArray(s) ? s.toString() : s, a, o, u, l, i, d, r]);
              (0, e.useEffect)(function () {
                h.current || !f.entry || u || l || m({
                  inView: !!c
                });
              });
              var y = [g, f.inView, f.entry];
              return y.ref = y[0], y.inView = y[1], y.entry = y[2], y;
            }({
              triggerOnce: !0
            }),
            l = t().useRef(0),
            c = i ? Math.abs(n).toLocaleString(o || "en-US") : String(Math.abs(n)),
            d = Array.from(c, Number).map((e, t) => isNaN(e) ? c[t] : e),
            [h, p] = t().useState(0),
            f = t().useRef(null),
            m = (e, t, n) => {
              return "function" == typeof e ? e(t, n) : e ? e[(s = 0, r = e.length - 1, s = Math.ceil(s), r = Math.floor(r), Math.floor(Math.random() * (r - s + 1)) + s)] : void 0;
              var s, r;
            };
          return t().useEffect(() => {
            var e, t;
            const n = null === (e = f.current.getClientRects()) || void 0 === e || null === (t = e[0]) || void 0 === t ? void 0 : t.height;
            n && p(n);
          }, [n, s]), t().createElement(t().Fragment, null, 0 !== h && t().createElement("div", {
            ref: a,
            style: {
              display: "flex",
              flexDirection: "row"
            },
            className: "animated-container"
          }, u && n < 0 && t().createElement("div", {
            style: s
          }, "-"), u && d.map((e, n) => "string" == typeof e ? t().createElement("div", {
            key: n,
            style: {
              ...s
            }
          }, e) : t().createElement("div", {
            key: n,
            style: {
              height: h,
              overflow: "hidden"
            }
          }, t().createElement(_n, {
            key: "" + l.current++,
            from: {
              transform: "translateY(0px)"
            },
            to: {
              transform: `translateY(${h * d[n] * -1 - 20 * h})`
            },
            config: m(r, e, n)
          }, e => Xn.map((n, r) => t().createElement(Dn.div, {
            key: r,
            style: {
              ...s,
              ...e
            }
          }, n)))))), t().createElement("div", {
            ref: f,
            style: {
              position: "absolute",
              top: -9999,
              ...s
            }
          }, 0));
        },
        es = t().memo(Kn, (e, t) => e.animateToNumber === t.animateToNumber && e.fontStyle === t.fontStyle && e.includeComma === t.includeComma);
    })(), i;
  })();
});
export default exports;